// HomePage.js
import React from 'react';
import Image from 'react-bootstrap/Image';
import myImage from '../images/imageOne.svg';

const HomePage = () => {
  const navbarTextStyle = {
    color: '#F4F3EE', // Replace with your desired color
    fontSize: '25px',
  };

  const imageStyle = {
    width: '400px', // Adjust the width as needed
    height: '400px', // Adjust the height as needed
  };

  return (
    <div>
      <h1 style={navbarTextStyle}>
        Need help on tracking your job applications? 
      </h1>
      <Image src={myImage} alt="imageOne" style={imageStyle} fluid />
      <h2 style={navbarTextStyle}>
        Click on Form to get started!
      </h2>
    </div>
  );
}

export default HomePage;
