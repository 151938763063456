import React, { useState, useEffect } from 'react';
import { db } from '../db/firebase'
import { Form, Row, Col, Table, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HistoryPage.css'; // Assuming you have a CSS file for additional styling
import Cookies from 'js-cookie';

const HistoryPage = () => {
    const [jobApplications, setJobApplications] = useState([]);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
      const fetchData = async () => {
        const userCookie = Cookies.get('user');
        const userId = userCookie ? JSON.parse(userCookie).id : null;
  
        if (userId) {
          const snapshot = await db.collection('jobApplications')
                                    .where('userId', '==', userId)
                                    .orderBy('createdAt', 'desc')
                                    .limit(limit)
                                    .get();
          const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setJobApplications(docs);
        }
      };
  
      fetchData();
    }, [limit]);

    const convertUnixTimeToLocalTime = (unixTime) => {
      return new Date(unixTime).toLocaleString();
  };
    return (
      <Container className="history-page">
        <Row className="align-items-center mb-4">
          <Col>
            <h1 className="page-title">Job Application History</h1>
          </Col>
          <Col className="text-right">
            <Form>
              <Form.Group as={Row} className="justify-content-end">
                <Form.Label column sm="auto">
                  Display:
                </Form.Label>
                <Col sm="auto">
                  <Form.Control as="select" value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Job Title</th>
              <th>Company Name</th>
              <th>Submission Date</th>
            </tr>
          </thead>
          <tbody>
            {jobApplications.map(app => (
              <tr key={app.id}>
                <td>{app.jobTitle}</td>
                <td>{app.companyName}</td>
                <td>{convertUnixTimeToLocalTime(app.createdAt)}</td>
                {/* Add other data columns if needed */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  };

export default HistoryPage;
