import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Cookies from 'js-cookie';
import './JobAppInfoForm.css';
import { db } from '../../db/firebase';

const JobAppInfoForm = () => {
  const [formData, setFormData] = useState({ jobTitle: '', jobLink: '', companyName: '', jobDescription: '' });
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.jobTitle) errors.jobTitle = 'Job title cannot be empty';
    if (!formData.companyName) errors.companyName = 'Company name cannot be empty';
    if (formData.jobLink && !isValidUrl(formData.jobLink)) errors.jobLink = 'Job link needs to follow the link pattern';
    return errors;
  };

  const displayMessage = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    const userCookie = Cookies.get('user');
    const userId = userCookie ? JSON.parse(userCookie).id : null;

    if (!userId) {
      displayMessage('error', 'You must be logged in to submit a form.');
      return;
    }

    if (Object.keys(errors).length === 0) {
      try {
        await db.collection('jobApplications').add({
          ...formData,
          userId: userId,
          createdAt: Date.now()
        });
        displayMessage('success', 'Form submitted successfully!');
        setFormData({ jobTitle: '', jobLink: '', companyName: '', jobDescription: '' });
      } catch (error) {
        console.error('Error writing document: ', error);
        displayMessage('error', 'Error submitting form. Please try again.');
      }
    } else {
      displayMessage('error', Object.values(errors).join('. '));
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="job-app-info-form">
      <Form.Group controlId="formJobLink">
        <Form.Label>Job Link</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Https://..." 
          name="jobLink"
          value={formData.jobLink}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="formJobTitle">
        <Form.Label>Job Title</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Software Engineer" 
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="formCompanyName">
        <Form.Label>Company Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Apple.." 
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="formJobDescription">
        <Form.Label>Job Description</Form.Label>
        <Form.Control 
          as="textarea" 
          placeholder="(Optional)" 
          name="jobDescription"
          value={formData.jobDescription}
          onChange={handleChange}
          rows={3}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>

      {message && (
        <div className={messageType === 'error' ? 'errorMessage' : 'successMessage'}>
          {message}
        </div>
      )}
    </Form>
  );
};

export default JobAppInfoForm;
