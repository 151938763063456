import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';
import { db } from './db/firebase';
import Cookies from 'js-cookie';
import JobAppInfoForm from './components/Form/JobAppInfoForm';
import HistoryPage from './pages/HistoryPage';
import Image from 'react-bootstrap/Image';
import HomePage from './pages/HomePage'; // Import the new image

import './App.css';

function App() {

  const getUserFromCookie = () => JSON.parse(Cookies.get('user') || '{}');
  
  useEffect(() => {
    const user = getUserFromCookie();
    if (user.access_token) {
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: 'application/json'
        }
      })
      .then((res) => {
        checkAndRegisterUser(res.data);
      })
      .catch((err) => console.log(err));
    }
  }, []);

  const checkAndRegisterUser = async (googleData) => {
    if (!googleData || !googleData.id) {
      console.error('Invalid Google data received');
      return;
    }

    const userRef = db.collection('users').doc(googleData.id);
    const doc = await userRef.get();

    if (!doc.exists) {
      await userRef.set({
        email: googleData.email,
        name: googleData.name,
        piture: googleData.picture,
      });
    }

    Cookies.set('user', JSON.stringify({ id: googleData.id, name: googleData.name, pic: googleData.picture}), { expires: 7, path: '/' });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      Cookies.set('user', JSON.stringify(codeResponse), { expires: 7, path: '/' });
      window.location.href = window.location.origin + '/form';
      window.location.reload();
      // Force reload
        },
    onError: (error) => console.log('Login Failed:', error)
  });

  const logOut = () => {
    googleLogout();
    Cookies.remove('user', { path: '/' });
    window.location.reload(); // Refresh page on login

  };


  const navbarStyle = {
    backgroundColor: '#463F3A', // Replace with your desired color
    height: '80px',
  };

  const navbarTextStyle = {
    color: '#F4F3EE', // Replace with your desired color\
    fontSize: '25px',
  };


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </span>
  ));

  const user = getUserFromCookie();
  return (
    <Router>
      <div className="App">
        {/* <Navbar bg="dark" variant="dark" expand="lg"> */}
        <Navbar style={navbarStyle}>
          <Container>
            <Navbar.Brand style={navbarTextStyle} href="/">JobTracker</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link style={navbarTextStyle} as={Link} to="/">Home</Nav.Link>
                <Nav.Link style={navbarTextStyle} as={Link} to="/form">Form</Nav.Link>
                <Nav.Link style={navbarTextStyle} as={Link} to="/history">History</Nav.Link>
              </Nav>
              {user && user.name ? (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <Image // Use Image component instead of FigureImage
                  src={user.pic} // Ensure you are using the correct key to access the picture URL
                  roundedCircle
                  style={{ width: '40px', height: '40px' }}
                  alt="Profile"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={logOut}>Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button variant="outline-light" onClick={() => login()}>Sign in with Google 🚀</Button>
          )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <header className="App-header">
            <Routes>
            <Route exact path="/" element={<HomePage />} /> {/* Add HomePage route */}
              <Route path="/form" element={user.name ? <JobAppInfoForm /> : <Navigate to="/" />} />
              <Route path="/history" element={user.name ? <HistoryPage /> : <Navigate to="/" />} />
              {/* Other routes */}
            </Routes>

          </header>
        </Container>
      </div>
    </Router>
  );
}

export default App;
