import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth'; // Import the necessary package for Firestore

const firebaseConfig = {
  apiKey:"AIzaSyBmz7Ee76d3RqTJgf4fZof80bNAHS4J3IE",
  authDomain:"jobapptracker-b06b8.firebaseapp.com",
  projectId:"jobapptracker-b06b8",
  storageBucket:"jobapptracker-b06b8.appspot.com",
  messagingSenderId:"254186261408",
  appId:"1:254186261408:web:38ef123bbb81bc6b2172c5",

};

!firebase.apps.length ? 
firebase.initializeApp(firebaseConfig) : 
firebase.app()

const auth = firebase.auth();

const db = firebase.firestore()
export { firebase, db, auth }